@font-face {
  font-family: "Gotham Narrow";
  font-style: normal;
  font-weight: normal;
  src: url("../../../axova-office-v5/src/assets/fonts/GothamNarrow-Book.otf");
}

@font-face {
  font-family: "Gotham Narrow";
  font-style: normal;
  font-weight: 300;
  src: url("../../../axova-office-v5/src/assets/fonts/GothamNarrow-Light.otf");
}

@font-face {
  font-family: "Gotham Narrow";
  font-style: normal;
  font-weight: 500;
  src: url("../../../axova-office-v5/src/assets/fonts/GothamNarrow-Medium.otf");
}

@font-face {
  font-family: "Gotham Narrow";
  font-style: normal;
  font-weight: 700;
  src: url("../../../axova-office-v5/src/assets/fonts/GothamNarrow-Bold.otf");
}

@font-face {
  font-family: "Gotham Narrow Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../../../axova-office-v5/src/assets/fonts/GothamNarrow-Bold.otf");
}
