@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";

// Vex Core
@import "./@vex/styles/core";

/**
 * Theme for Axova
 */
@import "theme/axova-fonts";
@import "theme/axova-vex-overrides";
@import "theme/axova-variables";
